:root{
  --navbar-height: #{60px};
  --navbar-height-total: #{calc(env(titlebar-area-height, 0px) + var(--navbar-height))};

  --body-bg: #{$body-bg};
  --body-bg-rgb: #{to-rgb($body-bg)};

  --content-bg: #{$content-bg};
  --content-bg-rgb: #{to-rgb($content-bg)};

  --list-bg: #{$list-bg};
  --list-bg-rgb: #{to-rgb($list-bg)};

  --navbar-bg: #{$navbar-bg};
  --navbar-bg-rgb: #{to-rgb($navbar-bg)};

  --sidebar-bg: #{$sidebar-bg};
  --sidebar-bg-rgb: #{to-rgb($sidebar-bg)};

  --sidebar-highlight: #{$sidebar-highlight};
  --sidebar-selected: #{$sidebar-selected};
  --sidebar-selected-bg: #{$sidebar-selected-bg};

  // additional bootstrap variables to be exposed at :root
  --card-bg: #{$card-bg};
  --card-bg-rgb: #{to-rgb($card-bg)};

  --input-btn-padding-y: #{$input-btn-padding-y};
  --input-btn-padding-x: #{$input-btn-padding-x};
  --input-border-color: #{$input-border-color};
  --input-border-radius: #{$input-border-radius};
  --input-font-size: #{$input-font-size};
  --border-color: #{$border-color};
  --dropdown-bg: #{$dropdown-bg};

  --bs-accordion-active-bg: #{$primary};
  --bs-accordion-active-color: #FFF;
}
